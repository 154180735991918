import { useState } from 'react'
import * as styles from './available-issues-table-read-only.css.ts'
import { ImportanceBadge } from './importance-badge'
import { PaginationControls } from './pagination-controls'
import { TableBodySkeleton } from './table-body-skeleton'

type CodeMod = {
  codemod_id: string
  name: string
  repositoryUrl: string
  docsUrl: string
  importance: 'LOW' | 'MEDIUM' | 'HIGH' | 'UNKNOWN'
}

type AvailableIssuesTableReadOnlyProps = {
  codemods: CodeMod[]
}

export function AvailableIssuesTableReadOnly({ codemods }: AvailableIssuesTableReadOnlyProps) {
  let [pageIndex, setPageIndex] = useState<number>(0)
  const pageSize = 5
  let maxIndex = Math.ceil(codemods.length / pageSize) - 1
  maxIndex = maxIndex > 0 ? maxIndex : 0
  if (pageIndex > maxIndex) setPageIndex(maxIndex)

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h2 className={styles.tableHeading}>Available ({codemods?.length ?? 0})</h2>
        <p className={styles.summonWith}>
          Summon with <span className={styles.summonWithBold}>@pixeebot next</span>
        </p>
      </div>
      <table className={styles.table} style={{ flexGrow: codemods?.length === 0 ? '1' : 'unset' }}>
        {!codemods ? (
          <TableBodySkeleton rowCount={5} columnCount={4} rowHeightInPixels={64} />
        ) : codemods.length === 0 ? (
          <tbody>
            <tr key={0}>
              <td colSpan={4} className={styles.emptyStateRow}>
                No data yet, stay tuned
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {codemods.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize).map(codemod => (
              <Row key={`${codemod.repositoryUrl} ${codemod.codemod_id}`} codemod={codemod} />
            ))}
          </tbody>
        )}
      </table>
      {codemods && codemods.length !== 0 && (
        <>
          <div style={{ flexGrow: 1 }}></div>
          <div className={styles.paginationContainer}>
            <PaginationControls
              tableLabel={'Available'}
              pageIndex={pageIndex}
              pageSize={pageSize}
              rowCount={codemods.length}
              onNextPage={() => setPageIndex(pageIndex => pageIndex + 1)}
              onPreviousPage={() => setPageIndex(pageIndex => pageIndex - 1)}
              onChangePageSize={undefined}
            />
          </div>
        </>
      )}
    </div>
  )
}

type RowProps = {
  codemod: CodeMod
}

const Row = ({ codemod }: RowProps) => {
  return (
    <tr className={styles.row}>
      <td className={styles.firstTD}>
        <ImportanceBadge variant={codemod.importance} />
      </td>
      <td className={styles.secondTD}>
        <a className={styles.pullRequestName} href={codemod.docsUrl} target="_blank">
          {codemod.name}
        </a>
        <br />
        <a className={styles.pullRequestLocation} href={codemod.repositoryUrl} target="_blank">
          {`${codemod.repositoryUrl.split('/')[3]} / ${codemod.repositoryUrl.split('/')[4]}`}
        </a>
      </td>
      <td className={styles.thirdTD}>
        <a className={styles.detailsLink} href={codemod.docsUrl} target="_blank">
          Details
        </a>
      </td>
    </tr>
  )
}
