export const Routes = {
  LoginPage: { path: '/login', params: [] },
  IntroPage: { path: '/intro', params: [] },
  WelcomePage: { path: '/welcome', params: [] },
  Installations: { path: '/installations', params: [] },
  AccountPage: { path: '/context/:accountLogin', params: [':accountLogin'] },
  RepositoryPage: { path: '/context/:accountLogin/:repositoryName', params: [':accountLogin', ':repositoryName'] },
  DeveloperDashboardPage: { path: '/', params: [] },
  DeveloperDashboardAccountPage: { path: '/:accountLogin', params: [':accountLogin'] },
  DeveloperDashboardRepositoryPage: {
    path: '/:accountLogin/:repositoryName',
    params: [':accountLogin', ':repositoryName'],
  },
  GenerateSingleFixPage: {
    path: '/fix/:installationId/:repositoryId/:analysisId/:codemodScheme/:codemodName',
    params: [':installationId', ':repositoryId', ':analysisId', ':codemodScheme', ':codemodName'],
  },
} as const

export type RouteKey = keyof typeof Routes
