import { ChatBubbleIcon } from '@radix-ui/react-icons'
import { useLocalStorage } from '@uidotdev/usehooks'
import { DateTime } from 'luxon'
import { useState } from 'react'
import Confetti from 'react-confetti'
import { useNavigate, useParams } from 'react-router-dom'
import { AvailableIssuesTableReadOnly } from '../components/available-issues-table-read-only'
import { CalculatedMetricsBar } from '../components/calculated-metrics-bar'
import { CodemodPreferenceConfigurationDialogOnboarding } from '../components/codemod-preference-configuration-dialog-onboarding'
import { PRsAreaChart } from '../components/prs-area-chart'
import { ScopeAndTimelineControls } from '../components/scope-and-timeline-controls'
import { SimpleIssuesTable } from '../components/simple-issues-table'
import { Toast } from '../components/toast'
import { ToastContainer } from '../components/toast-container'
import { filterByLatestAnalyses } from '../utils/filter-by-latest-analyses'
import { mapCodemodsImportanceToPRs } from '../utils/map-codemod-importance-to-pr'
import { toPRChartData } from '../utils/to-pr-chart-data'
import { useEnvironmentData } from '../utils/use-environment-data.ts'
import {
  useGetInstallations,
  useGetOwnerPreferences,
  useGetPixeebotPullRequests,
  useGetRepositories,
  useGetRepositoriesAnalysisStatusLogs,
  useGetRepositoriesCodemodHitStatuses,
  useGetUser,
} from '../utils/user-platform-api-hooks'
import { AnalysisStatusLogsByRepositoriesId, RepositoryWithInstallationId } from '../utils/user-platform-api-schemas.ts'
import * as styles from './developer-dashboard-page.css.ts'
import { useOutletData } from './root'

export function DeveloperDashboardPage() {
  const { setIsCheckInstallationsBreadcrumb } = useOutletData()

  const { accountLogin, repositoryName } = useParams()
  const scope = repositoryName ? `${accountLogin}/${repositoryName}` : (accountLogin ?? 'all')
  const navigate = useNavigate()

  const [isChartVisible, setIsChartVisible] = useLocalStorage<boolean>('ui-state/isChartVisible', true)
  const handleToggleIsChartVisible = () => setIsChartVisible(previous => !previous)

  const [timeRangeInDays, setTimeRangeInDays] = useLocalStorage<number>('ui-state/timeRangeInDays', 7)
  const handleTimeRangeChange = (value: string) => setTimeRangeInDays(Number(value))

  const [isHighImportanceEnabled, setIsHighImportanceEnabled] = useLocalStorage<boolean>(
    'ui-state/isHighImportanceEnabled',
    true
  )
  const [isMediumImportanceEnabled, setIsMediumImportanceEnabled] = useLocalStorage<boolean>(
    'ui-state/isMediumImportanceEnabled',
    true
  )
  const [isLowImportanceEnabled, setIsLowImportanceEnabled] = useLocalStorage<boolean>(
    'ui-state/isLowImportanceEnabled',
    true
  )

  const { data: user } = useGetUser()
  const { data: installations } = useGetInstallations()
  const { data: repositories } = useGetRepositories(installations.map(installation => installation.id))
  const { data: pullRequests, isPending: pullRequestsIsPending } = useGetPixeebotPullRequests(
    installations.map(installation => installation.account.login)
  )
  const pullRequestsWithImportance = pullRequests
    .filter(pullRequest => {
      if (scope === 'all') return true
      if (pullRequest.html_url.includes(scope)) return true
    })
    .filter(pullRequest => {
      const createdAt = DateTime.fromISO(pullRequest.created_at)
      const closedAt = DateTime.fromISO(pullRequest.closed_at!)
      const daysAgo = DateTime.now().minus({ days: timeRangeInDays })
      return createdAt > daysAgo || closedAt > daysAgo
    })
    .map(mapCodemodsImportanceToPRs)
    .filter(getFilterFunction(isHighImportanceEnabled, isMediumImportanceEnabled, isLowImportanceEnabled))
  const openPullRequests = pullRequestsWithImportance.filter(pullRequest => pullRequest.state === 'open')
  const mergedPullRequests = pullRequestsWithImportance.filter(
    pullRequest => pullRequest.state === 'closed' && pullRequest.pull_request?.merged_at
  )
  const closedPullRequests = pullRequestsWithImportance.filter(
    pullRequest => pullRequest.state === 'closed' && !pullRequest.pull_request?.merged_at
  )

  const { data: codemodsByRepository } = useGetRepositoriesCodemodHitStatuses(
    installations.map(installation => installation.id)
  )

  const codemods = codemodsByRepository
    .flatMap(({ repository_id, codemod_hit_statuses }) =>
      codemod_hit_statuses.map(codemod_hit_status => ({
        ...codemod_hit_status,
        repository_id,
        repositoryUrl: repositories.find(repository => repository.id === repository_id)?.html_url ?? '#',
        docsUrl: `https://docs.pixee.ai/codemods/${codemod_hit_status.codemod_id.replace(/pixee:(\w+)\//, (_, p1) => `${p1}/pixee_${p1}_`)}`,
      }))
    )
    .filter(codemod => codemod.available)
    .filter(codemod => {
      if (scope === 'all') return true
      if (codemod.repositoryUrl.includes(scope)) return true
    })
    .filter(getFilterFunction(isHighImportanceEnabled, isMediumImportanceEnabled, isLowImportanceEnabled))

  const { data: analysesByRepository, isPending: analysesIsPending } = useGetRepositoriesAnalysisStatusLogs(
    installations.map(installation => installation.id)
  )
  const scopeAnalyses = selectScopeAnalyses({
    scope,
    timeRangeInDays,
    analysesByRepository,
    repositories,
  })

  const { data: ownerPreferences, isPending: ownerPreferencesIsPending } = useGetOwnerPreferences(
    installations.map(installation => installation.account.id)
  )

  const isTriggerOnboarding =
    !ownerPreferencesIsPending && installations.length > 0 && ownerPreferences.length !== installations.length
  const [isOnboardingComplete, setIsOnboardingComplete] = useState<boolean>(false)
  const [isCheckInstallationsBanner, setIsCheckInstallationsBanner] = useLocalStorage<boolean>(
    'ui-state/isCheckInstallationsBanner',
    false
  )

  const environmentData = useEnvironmentData()

  return (
    <>
      {isCheckInstallationsBanner && (
        <ToastContainer>
          <Toast variant="info" emojiIcon="✅" handleClose={() => setIsCheckInstallationsBanner(false)}>
            <>
              <strong>Onboarding complete!</strong>
              {environmentData.githubAppName} is checking things out. Track the status of analysis under Installations.
            </>
          </Toast>
        </ToastContainer>
      )}
      {isOnboardingComplete && <Confetti width={window.innerWidth - 20} recycle={false} style={{ zIndex: 3001 }} />}
      {isTriggerOnboarding && !isOnboardingComplete && (
        <CodemodPreferenceConfigurationDialogOnboarding
          installationWithoutConfiguration={installations.find(
            ({ account: { id } }) => !ownerPreferences?.some(({ ownerId }) => id === ownerId)
          )}
          setIsOnboardingComplete={setIsOnboardingComplete}
          setIsCheckInstallationsBreadcrumb={setIsCheckInstallationsBreadcrumb}
          setIsCheckInstallationsBanner={setIsCheckInstallationsBanner}
        />
      )}
      <ScopeAndTimelineControls
        handleNavigate={value => {
          if (value === 'all') {
            navigate(`/`)
          } else {
            navigate(`/${value}`)
          }
        }}
        scope={scope}
        username={user?.login}
        installations={installations}
        repositories={repositories}
        isChartVisible={isChartVisible}
        handleToggleIsChartVisible={handleToggleIsChartVisible}
        timeRangeInDays={timeRangeInDays}
        handleTimeRangeChange={handleTimeRangeChange}
        isHighImportanceEnabled={isHighImportanceEnabled}
        isMediumImportanceEnabled={isMediumImportanceEnabled}
        isLowImportanceEnabled={isLowImportanceEnabled}
        handleToggleIsHighImportanceEnabled={() =>
          setIsHighImportanceEnabled(isHighImportanceEnabled => !isHighImportanceEnabled)
        }
        handleToggleIsMediumImportanceEnabled={() =>
          setIsMediumImportanceEnabled(isMediumImportanceEnabled => !isMediumImportanceEnabled)
        }
        handleToggleIsLowImportanceEnabled={() =>
          setIsLowImportanceEnabled(isLowImportanceEnabled => !isLowImportanceEnabled)
        }
      />
      {isChartVisible && (
        <PRsAreaChart
          data={toPRChartData(pullRequestsWithImportance, scopeAnalyses, timeRangeInDays)}
          isPending={pullRequestsIsPending}
        />
      )}
      <CalculatedMetricsBar
        {...{
          avgMergeRate: (mergedPullRequests.length / pullRequestsWithImportance.length) * 100,
          hoursSaved: mergedPullRequests.length * 1,
          mergedFixes: mergedPullRequests.length,
          prsHardened: scopeAnalyses.filter(analysis => analysis.type === 'PR_HARDENING').length,
          codemodsCount: codemods.length,
          analysesCompleted: scopeAnalyses.filter(
            analysis => analysis.status === 'COMPLETED_RESULTS' || analysis.status === 'COMPLETED_NO_RESULTS'
          ).length,
          analysesInProgressOrQueued: scopeAnalyses.filter(
            analysis => analysis.status === 'IN_PROGRESS' || analysis.status === 'QUEUED'
          ).length,
          pullRequestsIsPending,
          analysesIsPending,
          analysesIsEmpty: scopeAnalyses.length === 0,
        }}
      />
      <section className={styles.issuesSummarySection}>
        <div className={styles.issuesSummaryHeader}>
          <div>
            <h1 className={styles.issuesSummaryHeading}>Issue summary</h1>
            <p className={styles.issuesSummaryDateHint}>Last {timeRangeInDays} days</p>
          </div>
          <a className={styles.feedbackLink} href="https://tally.so/r/waYBjZ" target="_blank">
            <ChatBubbleIcon className={styles.feedbackIcon} />
            We want your feedback!
          </a>
        </div>
        <div className={styles.issuesTablesRow}>
          <div className={styles.table1}>
            <SimpleIssuesTable
              title="Open"
              pullRequests={openPullRequests.sort((a, b) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
              })}
            />
          </div>
          <div className={styles.table2}>
            <AvailableIssuesTableReadOnly
              codemods={codemods.sort((a, b) => {
                const importanceLevels = {
                  HIGH: 1,
                  MEDIUM: 2,
                  LOW: 3,
                }
                return importanceLevels[a.importance] - importanceLevels[b.importance]
              })}
            />
          </div>
        </div>

        <SimpleIssuesTable
          title="Completed"
          pullRequests={[...closedPullRequests, ...mergedPullRequests].sort((a, b) => {
            return new Date(b.closed_at!).getTime() - new Date(a.closed_at!).getTime()
          })}
        />
      </section>
    </>
  )
}

const getFilterFunction = (isHighImportanceEnabled, isMediumImportanceEnabled, isLowImportanceEnabled) => {
  return object => {
    const filters = [
      undefined,
      ...[
        isHighImportanceEnabled ? 'HIGH' : null,
        isMediumImportanceEnabled ? 'MEDIUM' : null,
        isLowImportanceEnabled ? 'LOW' : null,
      ].filter(filter => !!filter),
    ]

    return filters.includes(object.importance)
  }
}

export const selectScopeAnalyses = ({
  scope,
  timeRangeInDays,
  analysesByRepository,
  repositories,
  nowIsoTimestamp = DateTime.now().toISO(),
}: {
  scope: string
  timeRangeInDays: number
  analysesByRepository: AnalysisStatusLogsByRepositoriesId
  repositories: RepositoryWithInstallationId[]
  nowIsoTimestamp?: string
}) =>
  analysesByRepository
    .filter(({ repository_id }) => {
      if (scope === 'all') return true
      if (repositories.find(repository => repository.id === repository_id)?.html_url.includes(scope)) return true
    })
    .flatMap(({ analysis_status_logs }) => analysis_status_logs)
    .filter(
      analysis =>
        DateTime.fromISO(analysis.created_at) > DateTime.fromISO(nowIsoTimestamp).minus({ days: timeRangeInDays })
    )
    .filter(filterByLatestAnalyses())
